var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "ejs-chart",
        {
          ref: "chart",
          attrs: {
            id: "appointments-trials-over-no-new-appointments-chart",
            title: _vm.$t(
              "statistics.charts.appointmentsTrialsOverNoNewAppointments.title"
            ),
            primaryXAxis: _vm.primaryXAxis,
            primaryYAxis: _vm.primaryYAxis
          }
        },
        [
          _c(
            "e-series-collection",
            [
              _c("e-series", {
                attrs: {
                  dataSource: _vm.dataSource.data,
                  type: "Line",
                  xName: "date",
                  yName: "value",
                  name: "Ore lavorate",
                  marker: _vm.marker
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "statistics.charts.appointmentsTrialsOverNoNewAppointments.footer"
              )
            ) +
            ": " +
            _vm._s(_vm.averageString) +
            " "
        ),
        _c("span", { staticClass: "coloredDot", style: _vm.averageColor })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }