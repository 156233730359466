var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "ejs-chart",
        {
          ref: "chart",
          attrs: {
            id: "services-cost-over-revenue-chart",
            title: _vm.$t("statistics.charts.serviceCostOverRevenue.title"),
            primaryXAxis: _vm.primaryXAxis,
            primaryYAxis: _vm.primaryYAxis,
            tooltip: { enable: true },
            legendSettings: { visible: true, position: "Top" }
          }
        },
        [
          _c(
            "e-series-collection",
            _vm._l(_vm.dataSource.series, function(value) {
              return _c("e-series", {
                key: value,
                attrs: {
                  dataSource: _vm.dataSource.data,
                  enableComplexProperty: true,
                  type: "Column",
                  xName: "date",
                  yName: `values.${value}`,
                  name: value,
                  marker: _vm.marker
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("div", [
        _vm._v(
          " " +
            _vm._s(_vm.$t("statistics.charts.serviceCostOverRevenue.footer")) +
            ": " +
            _vm._s((_vm.dataSource.average * 100).toFixed(2)) +
            "% "
        ),
        _c("span", { staticClass: "coloredDot", style: _vm.averageColor })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }