var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        [_c("search-toolbar", { on: { search: _vm.updateCharts } })],
        1
      ),
      _c("div", { staticStyle: { "margin-top": "8px" } }, [
        _c("div", { staticClass: "e-btn-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.granularity,
                expression: "granularity",
                modifiers: { number: true }
              }
            ],
            attrs: {
              type: "radio",
              id: "day",
              name: "granularity",
              value: "1"
            },
            domProps: { checked: _vm._q(_vm.granularity, _vm._n("1")) },
            on: {
              change: function($event) {
                _vm.granularity = _vm._n("1")
              }
            }
          }),
          _c("label", { staticClass: "e-btn", attrs: { for: "day" } }, [
            _vm._v(_vm._s(_vm.$t("timePeriods.day")))
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.granularity,
                expression: "granularity",
                modifiers: { number: true }
              }
            ],
            attrs: {
              type: "radio",
              id: "week",
              name: "granularity",
              value: "2"
            },
            domProps: { checked: _vm._q(_vm.granularity, _vm._n("2")) },
            on: {
              change: function($event) {
                _vm.granularity = _vm._n("2")
              }
            }
          }),
          _c("label", { staticClass: "e-btn", attrs: { for: "week" } }, [
            _vm._v(_vm._s(_vm.$t("timePeriods.week")))
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.granularity,
                expression: "granularity",
                modifiers: { number: true }
              }
            ],
            attrs: {
              type: "radio",
              id: "month",
              name: "granularity",
              value: "3"
            },
            domProps: { checked: _vm._q(_vm.granularity, _vm._n("3")) },
            on: {
              change: function($event) {
                _vm.granularity = _vm._n("3")
              }
            }
          }),
          _c("label", { staticClass: "e-btn", attrs: { for: "month" } }, [
            _vm._v(_vm._s(_vm.$t("timePeriods.month")))
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.granularity,
                expression: "granularity",
                modifiers: { number: true }
              }
            ],
            attrs: {
              type: "radio",
              id: "year",
              name: "granularity",
              value: "5"
            },
            domProps: { checked: _vm._q(_vm.granularity, _vm._n("5")) },
            on: {
              change: function($event) {
                _vm.granularity = _vm._n("5")
              }
            }
          }),
          _c("label", { staticClass: "e-btn", attrs: { for: "year" } }, [
            _vm._v(_vm._s(_vm.$t("timePeriods.year")))
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.granularity,
                expression: "granularity",
                modifiers: { number: true }
              }
            ],
            attrs: {
              type: "radio",
              id: "total",
              name: "granularity",
              value: "6"
            },
            domProps: { checked: _vm._q(_vm.granularity, _vm._n("6")) },
            on: {
              change: function($event) {
                _vm.granularity = _vm._n("6")
              }
            }
          }),
          _c("label", { staticClass: "e-btn", attrs: { for: "total" } }, [
            _vm._v(_vm._s(_vm.$t("timePeriods.all")))
          ])
        ])
      ]),
      _c("ejs-tab", { attrs: { id: "statistics-tabs" } }, [
        _c("div", { staticClass: "e-tab-header" }, [
          _c("div", [_vm._v("Professionista")]),
          _c("div", [_vm._v("Società")]),
          _c("div", [_vm._v("Appuntamenti")])
        ]),
        _c("div", { staticClass: "e-content" }, [
          _c(
            "div",
            [
              _c("worked-hours-statistics", {
                ref: "workedHoursStatistics",
                attrs: { granularity: _vm.granularity }
              }),
              _c("services-trend-statistics", {
                ref: "serviceTrendStatistics",
                attrs: { granularity: _vm.granularity }
              }),
              _c("service-statistics", {
                ref: "serviceStatistics",
                attrs: { granularity: _vm.granularity }
              }),
              _c("service-employee-cost-statistics", {
                ref: "serviceEmployeeCostStatistics",
                attrs: { granularity: _vm.granularity, page: "performance" }
              }),
              _c("service-cost-revenue-statistics", {
                ref: "serviceCostOverRevenueStatistics",
                attrs: { granularity: _vm.granularity }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("service-profit-statistics", {
                ref: "serviceProfitStatistics",
                attrs: { granularity: _vm.granularity }
              }),
              _c("service-revenue-statistics", {
                ref: "serviceRevenueStatistics",
                attrs: { granularity: _vm.granularity }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("appointments-trials-statistics", {
                ref: "appointmentsTrialsStatistics",
                attrs: { granularity: _vm.granularity }
              }),
              _c("appointments-trials-over-no-new-appointments-statistics", {
                ref: "appointmentsTrialsOverNoNewAppointmentsStatistics",
                attrs: { granularity: _vm.granularity }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }